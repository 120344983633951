import * as Wildfire from "../../layers/static/Wildfire";

import base, { Config } from "../../config";
import { camerasAZGF, camerasSantaCruz } from "../../layers/misc/cameras";

import FEMA_NFHL from "../../layers/dynamic/FEMA_NFHL";
import { LatLng } from "leaflet";

const config: Config = {
  ...base,
  ...{
    title: "Santa Cruz County ALERT",
    center: new LatLng(31.5, -110.9),
    basemap: "Topographic",
    zoom: 9,
    agencyPassList: [
      "Santa Cruz County Flood Control District",
      "United States Geological Survey",
      "Arizona Game and Fish Department",
      "Pima County Regional Flood Control District",
    ],
    layers: {
      static: [Wildfire.last(3)],
      dynamic: [FEMA_NFHL],
      misc: [
        {
          title: "Webcams",
          layers: [
            { name: "Santa Cruz County", cameras: camerasSantaCruz },
            { name: "Arizona Game and Fish Department", cameras: camerasAZGF },
          ],
        },
      ],
      lightning: false,
      cbrfc: false,
    },
   },
};

export default config;
